import service from './config.js'
import link from './link.js'

let ApiUrl = link.UploadApplicationApI.APIService

//图片上转
export const UploadPicture = async data => {
	let responseData = await service({
		// url: ApiUrl + "UploadPicture.ashx",
		url: ApiUrl + '/UploadPictureToSize.ashx',
		method: 'post',
		headers: { 'Content-Type': 'multipart/form-data' },
		data: data,
	})
	return responseData
}
